import React, { Fragment } from "react";
import Footer from "./navigation/Footer";
import Navbar from "./navigation/Navbar";
import { Helmet } from "react-helmet";
export default function index({ children, title, desc }) {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={title} />
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </Fragment>
  );
}
