import React from "react";
import "./DojoKun.css";
import Swal from "sweetalert2";

const showInfoAlert = () => {
  Swal.fire({
    title: "Dojo Kun",
    text: "Dojo Kun es el código de ética de Kyokushin, el cual se recita al final de cada entrenamiento. Todas las frases comienzan con 'Hitotsu' que significa 'Uno' en japonés, dando a entender que todas las frases son igualmente importantes.",
    icon: "info",
    confirmButtonText: "Entendido",
  });
};

export default function DojoKun() {
  return (
    <div className="DojoKun">
      <h1 className="text-center bg-black text-white">Dojo Kun</h1>
      <img
        alt=""
        className="img-responsive img-rounded mx-auto d-block img-fluid"
        src="https://www.matsushimazonanorte.cl/img/blog/kyokushin_japanese_dojo_kun-1024x576.jpg"
        style={{ width: "auto", alignItems:"center", margin: "auto"}}
      />
      <div className="col-md-12">
        <div  style={{ display: "flex", alignItems: "center", justifyContent:"center"}}>
          <h4 className="text-center">En Japonés</h4>
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "blue", marginLeft: "5px", cursor: "pointer" }}
            onClick={showInfoAlert}
          ></i>
        </div>
        <section className="text-center">
          <p>
            <span className="dojo-kun-text">
              我々は、心身を鍛錬し、厳しいしきたりを守ることを極めること
              <br />
              <span className="text-black">
                Hitotsu, ware ware wa, shinshin o renmashi, kakko fubatsu no
                shingi o kiwameru koto.......
              </span>
            </span>
          </p>
          <p>
            <span className="dojo-kun-text">
              我々は、武の真髄を極め、気に発し、感に敏なること
              <br />
              <span className="text-black">
                Hitotsu, ware ware wa, bu no shinzui o kiwame, ki ni hasshi, kan
                ni bin naru koto......
              </span>
            </span>
          </p>

          <p>
            <span className="dojo-kun-text">
              我々は、実直剛健を以て、自己の精神を養うこと
              <br />
              <span className="text-black">
                Hitotsu, ware ware wa, shitsujitsu goken o motte, jiko no
                seishin o kanyo suru koto......
              </span>
            </span>
          </p>
          <p>
            <span className="dojo-kun-text">
              我々は、礼節を重んじ、長上を敬し、祖母の振舞をつつしむこと
              <br />
              <span className="text-black">
                Hitotsu, ware ware wa, reisetsu o omonji, chojo o keishi, sobo
                no furumai o tsutsushimu koto......
              </span>
            </span>
          </p>
          <p>
            <span className="dojo-kun-text">
              我々は、信仏を奉び、謙譲の美徳を忘れざること
              <br />
              <span className="text-black">
                Hitotsu, ware ware wa, shinbutsu o toutobi, kenjo no bitoku o
                wasurezaru koto......
              </span>
            </span>
          </p>

          <p>
            <span className="dojo-kun-text">
              我々は、知性と体力とを高じ、他に望まざること……
              <br />
              <span className="text-black">
                Hitotsu, ware ware wa, chisei to tairyoku to o kojo sase, koto
                ni nozonde ayamatazaru koto......
              </span>
            </span>
          </p>
        </section>

        <h4 className="text-center">En Español</h4>
        <section className="text-center text-black">
          <p>
            <span className="dojo-kun-text">
              Entrenaré firmemente mi corazón y mi cuerpo para tener un espíritu
              inconmovible...
            </span>
          </p>
          <p>
            <span className="dojo-kun-text">
              Alimentare la verdadera significación del arte marcial del Karate,
              para que a un debido tiempo mis sentidos puedan actuar mejor.
            </span>
          </p>
          <p>
            <span className="dojo-kun-text">
              Con verdadero vigor procuraré cultivar el espíritu de abnegación.
            </span>
          </p>
          <p>
            <span className="dojo-kun-text">
              Observaré las reglas de cortesía y respeto a mis superiores y me
              abstendré de la violencia.
            </span>
          </p>
          <p>
            <span className="dojo-kun-text">
              Seguiré a mi dios y eternas verdades y jamás olvidaré la verdadera
              virtud de la humildad.
            </span>
          </p>
          <p>
            <span className="dojo-kun-text">
              Miraré para lo alto, para la sabiduría y el poder, no procurando
              otro deseo.
            </span>
          </p>
          <p>
            <span className="dojo-kun-text">
              Toda mi vida y a través de la disciplina Karate Kyokushin,
              procuraré llenar la verdadera significación de la filosofía de la
              vida.
            </span>
          </p>
        </section>
      </div>
    </div>
  );
}
