import React from "react";
import { Link } from "react-router-dom";
export default function Aside({ categories, title, description }) {
  return (
    <div className="col-md-4">
      <div
        className="position-sticky"
        style={{ top: "2rem", textDecoration: "none" }}
      >
        <div className="p-4 mb-3 bg-dark rounded text-light">
          <h4 className="fst-italic">{title}</h4>
          <p className="mb-0">{description}</p>
        </div>
        {/* categories */}
        <div className="p-4">
          <h4 className="fst-bold ">Categorias</h4>
          <ol className="list-unstyled mb-2" style={{ textDecoration: "none" }}>
            <li>
              <Link to="/Noticias">Todo</Link>
            </li>
            {categories &&
              categories.map((category) => (
                <li style={{textDecoration:'none', listStyle:'none', color:"gray"}}>
                  <Link
                    to={`/Noticias/categoria/${category.slug}`}
                    aria-label={`Categoria F${category.slug}`}
                  >
                    {category.name}
                  </Link>
                </li>
              ))}
          </ol>
        </div>

        <div className="p-4">
          <h4 className="fst-italic">Siguenos en nuestras redes sociales</h4>
          <ol className="list-unstyled text-decoration-none list-group-item-success ">
            <li>
              <Link
                to="https://www.instagram.com/matsushimachile"
                rel="_blank"
                aria-label="Enlace hacia nuestro Instagram Oficial"
              >
                <i className="fa-brands fa-instagram"></i> Instagram
              </Link>
            </li>
            <li>
              <Link
                to="https://www.youtube.com/@matsushimachile"
                rel="_blank"
                aria-label="Enlace hacia nuestro canal de youtube Oficial"
              >
                <i className="fa-brands fa-youtube"></i> Youtube
              </Link>
            </li>
            <li>
              <Link
                aria-label="Enlace hacia nuestro Facebook Oficial"
                to="https://www.facebook.com/people/Honbu-Dojo/pfbid02gRJVoMaJZyh4JvDRf84M82FnxJDkQfkMdwY6HZ6RahUKgZAKVNTEdPjbVUVtLQCPl/?mibextid=ZbWKwL"
              >
                <i className="fa-brands fa-facebook"></i> Facebook
              </Link>
            </li>
            <li>
              <Link
                aria-label="Enlace hacia nuestro Tiktok Oficial"
                to="https://www.tiktok.com/@matsushimachile"
                rel="_blank"
              >
                {" "}
                <i className="fa-brands fa-tiktok"></i> Tiktok
              </Link>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
