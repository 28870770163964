import React from "react";
import { Link } from "react-router-dom";

export default function BlogCardv3({
  posts,
}) {
  return (
    <>
      {posts && posts.length > 0 ? (
        posts.map((post, index) => (
          <div className="col-md-0" key={index}>
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <strong className="d-inline-block mb-2 text-primary">
                  {post.category.name}
                </strong>
                <h3 className="mb-0">{post.title}</h3>
                <div className="mb-1 text-muted">{post.date}</div>
                <p className="card-text mb-auto">
                  {post.description.length > 100
                    ? post.description.slice(0, 70).concat("...")
                    : post.description}
                </p>
                <Link to={`/Noticias/${post.slug}`} className="stretched-link">
                  Continuar leyendo
                </Link>
              </div>
              <div className="col-auto d-none d-lg-block">
                <img
                  src={post.thumbnail}
                  alt={post.title}
                  width="200"
                  height="250"
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <span className="d-inline-block mx-3 position-relative" data-filter="1">
          No hay publicaciones
        </span>
      )}
    </>
  );
}
