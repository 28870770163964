import { useState, useEffect } from "react";
import logo from "components/assets/img/logo.jpg";
export default function Cards({ dojos }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!dojos) return;

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [dojos]);

  if (!dojos) return null; // Agregar una verificación adicional aquí

  const filteredDojos = dojos.filter(
    (dojo) => dojo.is_branch_chief === "Si" || dojo.is_dojoOperator === "Si"
  );

  return (
    <section className="BDMatsushima">
      <div class="container">
        <div class="row">
          {filteredDojos.map((dojo) => (
            <div className="col-md-4 mt-4">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  {loading ? (
                    <div
                      className="skeleton-loading w-100"
                      style={{ height: "200px" }}
                    ></div>
                  ) : (
                    <img
                      className="card-img-top"
                      src={
                        dojo.profile_image
                          ? dojo.profile_image
                          : logo
                      }
                      alt={dojo.cargo +" "+ dojo.full_name}
                      preload="auto"
                      height={250}
                    />
                  )}
                </div>
                <div className="card-body pt-0">
                  {loading ? (
                    <div
                      className="skeleton-loading w-100 mb-2"
                      style={{ height: "20px" }}
                    ></div>
                  ) : (
                    <h5 className="card-title text-center">
                      {dojo.cargo} {dojo.full_name}
                    </h5>
                  )}
                </div>
              </div>
              {!loading && (
                <p className="mt-1 w-100 float-left text-center">
                  <strong>
                    {dojo.is_branch_chief || dojo.is_dojoOperator ? (
                      <>
                        <span>
                          {dojo.is_branch_chief === "No" &&
                            dojo.is_dojoOperator === "Si" && (
                              <span>Dojo Operador</span>
                            )}
                          {dojo.is_branch_chief === "Si" &&
                            dojo.is_dojoOperator === "No" && (
                              <span>Branch Chief</span>
                            )}
                          {dojo.is_branch_chief === "Si" &&
                            dojo.is_dojoOperator === "Si" && (
                              <span>Branch Chief y Dojo Operador</span>
                            )}
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </strong>
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
