import { useEffect } from "react";
import { connect } from "react-redux";
import Default from "components/Layout/Default";
import Categories from "./Categories";
import ActividadesList from "./ActividadesList";
import {
  get_actividades_list_category,
  get_actividades_list_category_page,
} from "./../../../redux/actions/actividades/actividades";
import { get_categories } from "./../../../redux/actions/categories/categories";
import { useParams } from "react-router-dom";
function Actividades({
  get_categories,
  categories,
  get_actividades_list_category,
  get_actividades_list_category_page,
  posts,
  count,
  next,
  previous,
}) {
  const params = useParams();
  const slug = params.slug;
  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);
        await get_categories();
        // Verificar si el slug está definido antes de hacer la solicitud
        if (slug) {
          await get_actividades_list_category(slug);
        } else {
          await get_actividades_list_category();
        }
      } catch (error) {
        console.error("Error al obtener las actividades:", error);
      }
    };
    fetchData();
  }, [get_categories, get_actividades_list_category, slug]);
  const handlePageChange = (page) => {
    // Llamar a la acción para obtener la siguiente página de blogs de la categoría
    get_actividades_list_category_page(slug, page);
  };

  return (
    <Default
      title={"Actividades | Iko Matsushima Chile"}
      desc={"Conoce las actividades que se realizan en Iko Matsushima Chile"}
      url={"https://www.ikomatsushima.cl/Actividades"}
    >
      <div className="background-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#273036"
            fillOpacity="0.2"
            d="M0,320L34.3,293.3C68.6,267,137,213,206,213.3C274.3,213,343,267,411,272C480,277,549,235,617,218.7C685.7,203,754,213,823,218.7C891.4,224,960,224,1029,229.3C1097.1,235,1166,245,1234,250.7C1302.9,256,1371,256,1406,256L1440,256L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
          ></path>
        </svg>
        <div className="text-dark  border rounded border-0  d-flex flex-column justify-content-between flex-lg-row p-4 p-md-6">
          <div className="">
            <h1 className="fw-bold text-center mb-3">
              Descubre las actividades que se realizan en Iko Matsushima Chile
            </h1>
            <h3 className="text-center">
              desde campeonatos, seminarios, examenes de grado y mucho más.
            </h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div>
          <Categories
            categories={categories && categories}
            posts={posts && posts}
          />
          <ActividadesList
            categories={categories && categories}
            get_actividades_list_page={handlePageChange && handlePageChange}
            post={posts && posts}
            count={count && count}
            next={next && next}
            previous={previous && previous}
          />
        </div>
      </div>
    </Default>
  );
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  posts: state.actividades.activity_list_category,
  count: state.actividades.count,
  next: state.actividades.next,
  previous: state.actividades.previous,
});

export default connect(mapStateToProps, {
  get_categories,
  get_actividades_list_category,
  get_actividades_list_category_page,
})(Actividades);
