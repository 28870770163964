import React from 'react'
import { Link } from 'react-router-dom'
export default function NoticiasCat({categories}) {
  return (
    <div className="nav-scroller py-1 mb-2">
        <nav className="nav d-flex justify-content-between">
          {categories &&
            categories.map((category) => (
              <Link
                className="p-2 link-secondary"
                style={{textDecoration: 'none'}}
                to={`/Noticias/categoria/${category.slug}`}
                key={category.id}
                aria-label={`Categoria ${category.slug}`}
                
              >
                {category.name}
              </Link>
            ))}
        </nav>
      </div>
  )
}
