import React, { Fragment } from "react";
import SmallSetPagination from "./../../pagination/SmallSetPagination";
import Cards from "./Cards";
export default function ActividadesList({
  next,
  previous,
  post,
  count,
  get_actividades_list_page,
}) {
  const handleNextPage = (event, nextPage) => {
    event.preventDefault();
    // Lógica para cambiar la página
    get_actividades_list_page(nextPage);
    // Desplazar hacia arriba después de cambiar la página
    window.scrollTo(0, 0);
  };
  const handlePreviousPage = (event, previousPage) => {
    event.preventDefault();
    // Lógica para cambiar la página
    get_actividades_list_page(previousPage);
    // Desplazar hacia arriba después de cambiar la página
    window.scrollTo(0, 0);
  };
  return (
    <>
      {post && post.length > 0 ? (
        <Fragment>
          <div className="mt-5">
            <Cards posts={post && post} />
          </div>
            <SmallSetPagination
              list_page={get_actividades_list_page && get_actividades_list_page}
              count={count && count}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
            />
        </Fragment>
      ) : (
        <div className="alert alert-warning text-center bg-red" role="alert">
          No hay actividades para mostrar
        </div>
      )}
    </>
  );
}
