import React from "react";
import moment from "moment";
export default function Footer() {
  const year = moment().format("YYYY");
  return (
    <div className=" mt-5 px-5">
      <footer className="bg-white border-top p-2 text-muted large  ">
        <div className="row align-items-center justify-content-between">
          <div>
            <span className="navbar-brand mr-2">
              <strong>IKO MATSUSHIMA CHILE</strong>
            </span>{" "}
            Copyright &copy; {year}. Todos los derechos reservados.
          </div>
        </div>
      </footer>
    </div>
  );
}
