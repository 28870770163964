import React from "react";
import { Link } from "react-router-dom";
export default function Navbar({ title }) {
  return (
    <header className="blog-header py-3">
      <div className=" row flex-nowrap justify-content-between align-items-center">
        <div className="col-4 pt-1"> </div>
        <div className="col-4 text-center">
          <Link className="blog-header-logo text-dark" to="/Noticias" aria-label="Regreso a la seccion de Noticias">
            {title ? title : "Noticias"}
            
          </Link>
        </div>
        <div className="col-4 d-flex justify-content-end align-items-center">
          <Link
            className="btn btn-sm btn-outline-secondary"
            to={"/"}
            aria-label="Regreso a la pagina de Inicio"
          >
            Volver
          </Link>
        </div>
      </div>
    </header>
  );
}
