import React from "react";
import { useNavigate } from "react-router-dom";
export default function NoticiasHeader({ title, subtitle }) {
  const navigate = useNavigate();
  return (
    <div className="p-4 p-md-5 mb-4 text-white rounded bg-dark">
      <div className="col-md-6 px-0">
        <h1 className="display-4 fst-italic">{title}</h1>
        <p className="lead my-3">{subtitle}</p>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => navigate("/Actividades")}
        >
          Revisa nuestras Actividades aqui
        </button>
      </div>
    </div>
  );
}
