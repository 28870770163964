// Browser.jsx
import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "components/pages/error/NotFound";
import Home from "components/pages/Home";
import Nacional from "components/pages/Nosotros/Dojos/Nacional/Instructores";
import Operadores from "components/pages/Nosotros/Dojos/Operadores/Instructores";
import FundPres from "components/pages/Nosotros/FundPres";
import DojoKun from "components/pages/Kyokushin/DojoKun";
import Labels from "components/pages/Kyokushin/Labels";
import Blog from "components/pages/blog/Blog";
import Categorias from "components/pages/blog/Categorias";
import Details from "components/pages/blog/Details";
import Actividades from "components/pages/Event/Actividades";
import CatEvent from "components/pages/Event/Categorias";
import Gallery from "components/pages/gallery";
import CategoriasGaleria from "components/pages/gallery/Categorias";
import Loading from "components/pages/loading/load";
function Browser() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simular un tiempo de carga
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Ajusta este tiempo según tus necesidades

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {isLoading && (
        <>
          {/* Muestra el componente de carga según la ruta actual */}
          {location.pathname === "/" && <Loading title={"Bienvenido a la Pagina Oficial de Iko Matsushima en Chile"}/>}
          {location.pathname === "/Actividades" && <Loading title={"Cargando las Actividades Oficiales de Iko Matsushima Chile"} />}
          {location.pathname === "/Fundador&Presidente" && <Loading title={"Cargando informacion sobre el Fundador y Presidente de Iko Matsushima Chile"} />}
          {location.pathname === "/Dojo-operadores" && <Loading title={"Cargando Dojo Operadores de Iko Matsushima Chile"} />}
          {location.pathname === "/Dojos-en-Chile" && <Loading title={"Cargando Dojos en Chile de Iko Matsushima Chile"} />}
          {location.pathname === "/DojoKun" && <Loading title={"Cargando Dojo Kun de Iko Matsushima Chile"} />}
          {location.pathname === "/Etiquetas-del-Dojo" && <Loading title={"Cargando las etiquetas del Dojo de Iko Matsushima Chile"} />}
          {location.pathname === "/Galeria" && <Loading title={"Cargando la Galeria de Iko Matsushima Chile"} />}   
          {location.pathname === "/Galeria/categoria/:slug" && <Loading title={"Cargando la Galeria de Iko Matsushima Chile"} />}
          {location.pathname === "/Noticias" && <Loading title={"Cargando las Noticias de Iko Matsushima Chile"} />}
          {location.pathname === "/Actividades/categoria/:slug" && <Loading title={"Cargando las Actividades de Iko Matsushima Chile"} />}
          {location.pathname === "/Noticias/categoria/:slug" && <Loading title={"Cargando las Noticias de Iko Matsushima Chile"} />}    
          {location.pathname === "/Noticias/:slug" && <Loading title={"Cargando las Noticias de Iko Matsushima Chile"} />}          
        </>
      )}
      
      
      {!isLoading && (
        <Routes location={location} key={location.pathname}>
          {/* Error Pages */}
          <Route path="*" element={<NotFound />} />
          {/* HOME */}
          <Route path="/" element={<Home />} />
          {/* Actividades */}
          <Route path="/Actividades" element={<Actividades />} />
          <Route path="/Actividades/categoria/:slug" element={<CatEvent />} />
          {/* Nosotros */}
          <Route path="/Fundador&Presidente" element={<FundPres />} />
          <Route path="/Dojo-operadores" element={<Operadores />} />
          <Route path="/Dojos-en-Chile" element={<Nacional />} />
          {/* Kyokushin */}
          <Route path="/DojoKun" element={<DojoKun />} />
          <Route path="/Etiquetas-del-Dojo" element={<Labels />} />
          {/* Noticias */}
          <Route path="/Noticias" element={<Blog />} />
          <Route path="/Noticias/categoria/:slug" element={<Categorias />} />
          <Route path="/Noticias/:slug" element={<Details />} />
          {/* Galeria */}
          <Route path="/Galeria" element={<Gallery />} />
          <Route path="/Galeria/categoria/:slug" element={<CategoriasGaleria />} />
        </Routes>
      )}
    </>
  );
}

export default Browser;
