import React from "react";
import { Link, useLocation } from "react-router-dom";
export default function Categories({ categories }) {
  const location = useLocation();

  return (
    <>
      <div className="filtr-controls text-center lead text-uppercase mb-3">
        <Link
          to="/Galeria"
          className={`${
            location.pathname === "/Galeria"
              ? "active-category selected-category"
              : "hover-category"
          }`}
        >
          Todo
        </Link>
        {categories &&
          categories.map((category, index) => (
            <Link
              to={`/Galeria/categoria/${category.slug}`}
              className={`${
                location.pathname === `/Galeria/categoria/${category.slug}`
                  ? "active-category selected-category"
                  : "hover-category"
              }`}
              key={index}
              data-filter={category.id}
            >
              {category.name}
            </Link>
          ))}
      </div>
    </>
  );
}
