import React, { useEffect } from "react";
import { connect } from "react-redux";
// import Layout from "components/Layout/index";
import { get_categories } from "./../../../redux/actions/categories/categories";
import Default from "components/Layout/Default";
import Categories from "./Categories";
import {
  get_actividades_list,
  get_actividades_list_page,
  get_actividades_list_category_page,
} from "./../../../redux/actions/actividades/actividades";
import ActividadesList from "./ActividadesList.js";
import HeaderSvg from "components/common/header/HeaderSvg";
function Actividades({
  get_categories,
  get_actividades_list_page,
  get_actividades_list,
  actividades,
  categories,
  posts,
  count,
  next,
  previous,
}) {
 useEffect(() => {
  const fetchData = async () => {
    try {
      await Promise.all([
        get_categories(),
        get_actividades_list(),
      ]);
    } catch (error) {
      alert("No se pudieron cargar las actividades y categorías.");
      console.error("Error cargando datos: ", error);
    }
  };
  fetchData();
}, [get_categories, get_actividades_list]);
  return (
    <Default
      title={"Actividades | Iko Matsushima Chile"}
      desc={"Conoce las actividades que se realizan en Iko Matsushima Chile"}
      url={"https://www.ikomatsushima.cl/Actividades"}
    >
      <HeaderSvg
        title={
          " Descubre las actividades que se realizan en Iko Matsushima Chile"
        }
        desc={"desde campeonatos, seminarios, examenes de grado y mucho más."}
      />
      <div className="container">
        <div>
          <Categories categories={categories && categories} />
          <ActividadesList
            categories={categories && categories}
            get_actividades_list_page={
              get_actividades_list_page && get_actividades_list_page
            }
            post={posts && posts}
            count={count && count}
          />
        </div>
      </div>
    </Default>
  );
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  posts: state.actividades.activity_list,
  count: state.actividades.count,
  next: state.actividades.next,
  previous: state.actividades.previous,
});

export default connect(mapStateToProps, {
  get_categories,
  get_actividades_list,
  get_actividades_list_page,
  get_actividades_list_category_page,
})(Actividades);
