import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "components/assets/img/Logo.webp";


const menuItems = [
  { label: "Inicio", path: "/" },
  { label: "Nosotros", path: "/Nosotros", ariaLabel: "Seccion de Nosotros", subMenu: [
    { label: "Fundador y Presidente", path: "/Fundador&Presidente", ariaLabel: "Fundador y Presidente de la organizacion" },
    { label: "Dojos a nivel Nacional", path: "/Dojos-en-Chile", ariaLabel: "Instructores y Dojos a nivel Nacional" },
    { label: "Dojo operadores", path: "/Dojo-operadores", ariaLabel: "Dojos Operadores y Branchief Oficiales" }
  ]},
  { label: "Actividades", path: "/Actividades", ariaLabel: "Seccion de Actividades" },
  { label: "Galeria", path: "/Galeria", ariaLabel: "Seccion de Galeria" },
  { label: "KYOKUSHIN", path: "#", ariaLabel: "Seccion de Kyokushin", subMenu: [
    { label: "Dojo Kun", path: "/DojoKun", ariaLabel: "Juramento / DojoKun" },
    { label: "Etiquetas del Dojo", path: "/Etiquetas-del-Dojo", ariaLabel: "Codigo del Dojo" }
  ]},
];

function Navbar() {
  const location = useLocation();

  return (
    <nav className="navbar navbar-expand-md bg-body py-2">
      <div className="container-fluid">
        <Link to="/" aria-label="Ir a la pagina de Inicio">
          <img
            className="img-fluid"
            src={logo}
            width="228"
            height="57"
            alt=""
            rel="preload"
          />
        </Link>
        <button
          data-bs-toggle="collapse"
          className="navbar-toggler"
          data-bs-target="#navcol-1"
        >
          <span className="visually-hidden"></span>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navcol-1">
          <ul className="navbar-nav me-auto">
            {menuItems.map((item, index) => (
              <MenuItem key={index} item={item} currentPath={location.pathname} />
            ))}
          </ul>
        </div>
        <Link to={"/Noticias"} className="btn btn-primary" aria-label="Ultimas Noticas Iko Matsushima Chile">Ultimas Noticias</Link>
      </div>
    </nav>
  );
}

function MenuItem({ item, currentPath }) {
  const { label, path, ariaLabel, subMenu } = item;

  const isActive = path === currentPath;

  if (subMenu) {
    return (
      <li className={`nav-item dropdown ${isActive ? 'active' : ''}`}>
        <Link
          className={`dropdown-toggle nav-link ${isActive ? 'active-link' : ''}`}
          aria-expanded="false"
          data-bs-toggle="dropdown"
          to={path}
          aria-label={ariaLabel}
        >
          {label}
        </Link>
        <div className="dropdown-menu">
          {subMenu.map((subItem, index) => (
            <Link
              key={index}
              className="dropdown-item"
              to={subItem.path}
              aria-label={subItem.ariaLabel}
            >
              {subItem.label}
            </Link>
          ))}
        </div>
      </li>
    );
  }

  return (
    <li className={`nav-item ${isActive ? 'active' : ''}`}>
      <Link className={`nav-link ${isActive ? 'active-link' : ''}`} to={path} aria-label={ariaLabel}>
        {label}
      </Link>
    </li>
  );
}

export default Navbar;
