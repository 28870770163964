import React from "react";
import Layout from "components/Layout/Default";
import Labels from "./labels"

export default function index() {
  return <Layout title={"Reglas del Dojo | Iko Matsushima Chile"}
    desc={"Conoce las reglas y etiquetas que se deben seguir dentro de un Dojo en Iko Matsushima Chile."}
    url={"https://www.ikomatsushima.cl/Etiquetas-del-Dojo"}
  >
    
    <Labels/>
  </Layout>;
}
