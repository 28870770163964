import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import logo from "components/assets/img/logo.jpg";
export default function BlogCards({ data, index }) {
  const dateMoment = moment(data.published).locale("es");
  const defaultImage = logo;

  return (
    <div className="col-md-4 on-hover">
      <div className="card border-0 mb-4">
        <Link to={`/Noticias/${data.slug}`}>
          <img
            className="card-img-top"
            src={data.thumbnail}
            alt={data.title}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultImage;
            }}
            width={300}
            height={200}
            rel="preload"
          />
        </Link>
        <div className="date-pos bg-info-gradiant p-2 d-inline-block text-center rounded text-white position-absolute">
          {dateMoment.format("MMM")}
          <span className="d-block"> {dateMoment.format("DD")}</span>
        </div>
        <h5 className="font-weight-medium mt-3">
          <a href="/#" className="text-decoration-none link">
            {data.title.length > 40 ? data.title.slice(0, 79) : data.title}
          </a>
        </h5>
        <div>
          <span>
            <Link to={`/Noticias/categoria/${data.category.slug}`}>
              {data.category.name}
            </Link>
          </span>
          &nbsp;
          <span className="mt-2 mx-2 font-medium text-gray-800 text-sm">
            {data.time_read} min de lectura
          </span>
        </div>
        <p className="my-2">
          {data.description.length > 74
            ? data.description.slice(0, 80) + "..."
            : data.description}
        </p>
        <Link
          to={`/Noticias/${data.slug}`}
          className="text-decoration-none linking text-themecolor mt-2"
        >
          Continua Leyendo... <i className="ti-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
}
