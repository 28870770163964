import React, { Fragment } from "react";
import Cards from "components/pages/gallery/Cards";
import SmallSetPagination from "components/pagination/SmallSetPagination";
export default function GaleriaList({
  next,
  previous,
  post,
  count,
  get_gallery_list_page,
}) {
  const handleNextPage = (event, nextPage) => {
    event.preventDefault();
    // Lógica para cambiar la página
    get_gallery_list_page(nextPage);
    // Desplazar hacia arriba después de cambiar la página
    window.scrollTo(0, 0);
  };
  const handlePreviousPage = (event, previousPage) => {
    event.preventDefault();
    // Lógica para cambiar la página
    get_gallery_list_page(previousPage);
    // Desplazar hacia arriba después de cambiar la página
    window.scrollTo(0, 0);
  };
  return (
    <>
      {post && post.length > 0 ? (
        <Fragment>
          <div className="mt-5">
            <section className="gallery-container">
              <Cards posts={post && post} />
            </section>
          </div>
          <SmallSetPagination
            list_page={get_gallery_list_page && get_gallery_list_page}
            count={count && count}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
          />
        </Fragment>
      ) : (
        <div className="alert alert-warning text-center bg-red" role="alert">
          No hay Galeria de Imagenes para mostrar
        </div>
      )}
    </>
  );
}
