import React, { useState, useEffect } from "react";
import Layout from "components/Layout/Default";
import { connect } from "react-redux";
import { get_dojos } from "./../../../../../redux/actions/dojos/dojos";
import Cards from "./Cards";

function Instructores({ get_dojos, dojos }) {
  const [setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0); // Scroll to the top of the page
        await get_dojos();
      } catch (error) {
        // Manejar el error estableciendo el estado de error
        console.error(error);
      }
    };
    fetchData();
  }, [get_dojos, setError]);

  return (
    <Layout
      title={"Instructores a Nivel Nacional | Iko Matsushima Chile"}
      desc={
        "¡Déjate llevar por la pasión del Karate Kyokushin! Conoce a nuestros instructores y dojos en todo el país, encuentra el más cercano a ti y comienza tu entrenamiento."
      }
      url={"https://www.ikomatsushima.cl/Dojos-en-Chile"}
    >
      
      <Cards dojos={dojos && dojos} />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  dojos: state.dojos.dojo_list,
});

export default connect(mapStateToProps, {
  get_dojos,
})(Instructores);
