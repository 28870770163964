import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

function Loading({title}) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulando una carga de 2 segundos
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      <div className="text-center">
        <h1 className="display-4 mb-4">
         {title}
        </h1>
        {isLoading ? (
          <>
            <Spinner
              animation="border"
              role="status"
              variant="primary"
              className="mb-3"
            />
            <p className="lead"></p>
          </>
        ) : (
          <p className="lead">¡Gracias por tu paciencia!</p>
        )}
      </div>
    </div>
  );
}

export default Loading;
