import React from "react";

const zonaOrder = { Norte: 2, Centro: 1, Sur: 3 };

function Cards({ dojos }) {
  if (!dojos || dojos.length === 0) {
    return <p>Actualmente No tenemos Directores en nuestra Organización</p>;
  }

  const sortedDojos = dojos
    .filter((dojo) => dojo.is_Director === "Si")
    .sort((a, b) => zonaOrder[a.zona] - zonaOrder[b.zona]);

  return (
    <>
      {sortedDojos.map((dojo) => (
        <div key={dojo.id} className="container">
          <div className="row">
            <div className="col-md-0">
              <img
                src={dojo.profile_image}
                alt={"Foto de " + dojo.cargo + " " + dojo.full_name}
                className="img-fluid"
                rel="preload"
                width={250}
                height={250}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://via.placeholder.com/250";
                }}
              />
            </div>
            <div className="col-md-12">
              <div className="pt-2">
                <h5 className="mt-4 font-weight-medium mb-0">
                  {dojo.cargo} {dojo.full_name}
                </h5>
                <h6 className="subtitle">Director Zona {dojo.zona}</h6>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default Cards;
