import React from "react";
import oss from "../../../../../src/assets/home/Labels/oss.webp";
import oss2 from "../../../../../src/assets/home/Labels/oss2.webp";
import seisa from "../../../../../src/assets/home/Labels/seisa.webp";
import dogi from "../../../../../src/assets/home/Labels/dogi.webp";
import prohibido from "../../../../../src/assets/home/Labels/prohibido.webp";
import rapides from "../../../../../src/assets/home/Labels/rapides.webp";
import kumite from "../../../../../src/assets/home/Labels/kumite.webp";
import kumite1 from "../../../../../src/assets/home/Labels/kumite1.webp";
import dan from "../../../../../src/assets/home/Labels/dan.webp";
import dogi1 from "../../../../../src/assets/home/Labels/dogi1.webp";
import respeto from "../../../../../src/assets/home/Labels/respeto.webp";
import osu from "../../../../../src/assets/home/Labels/osu.webp";
import prohibido1 from "../../../../../src/assets/home/Labels/señaletica.webp";
import limpio from "../../../../../src/assets/home/Labels/limpio.webp";
import limpiar from "../../../../../src/assets/home/Labels/limpieza.webp";

const GalleryComponent = () => {
  return (
    <div className="ETQ">
      {" "}
      <h1 className="text-center bg-black text-white">Reglas Del Dojo</h1>
      <div className=" container">
        <div className="card-left">
          <div className="card-image">
            <img src={oss} alt="" />
          </div>
          <div className="card-text">
            <p>
              Al entrar o salir del dojo, siempre mire al frente, inclínese y
              diga Osu.
            </p>
          </div>
        </div>
        <div className="card-right">
          <div className="card-image">
            <img alt="" src={oss2} />
          </div>
          <div className="card-text">
            <p>
              Luego, enfréntese a la dirección general de los otros estudiantes
              en la clase, inclínese y diga otra vez Osu.
            </p>
          </div>
        </div>
        <div className="card-right">
          <div className="card-image">
            <img alt="" src={seisa} />
          </div>
          <div className="card-text">
            <p>
              Si llegas tarde, debes arrodillarte en "Seisa" y cerrar los ojos
              "Mokuso", una vez el maestro diga "Tate" debes pararte y decir
              Oss,shitsurei-shimasu "perdon por la tardanza e ir a formar
              rapidamente"{" "}
            </p>
          </div>
        </div>
        <div className="card-left">
          <div className="card-image">
            <img alt="" src={dogi} />
          </div>
          <div className="card-text">
            <p>
              No retire ninguna parte de su dogi durante el entrenamiento sin
              que se lo indiquen.
            </p>
          </div>
        </div>
        <div className="card-left">
          <div className="card-image">
            <img alt="" src={prohibido} />
          </div>
          <div className="card-text">
            <p>No coma, mastique chicle, fume o beba en el dojo.</p>
          </div>
        </div>
        <div className="card-right">
          <div className="card-image">
            <img alt="" src={rapides} />
          </div>
          <div className="card-text">
            <p>
              Cuando se le pida que avance a una posición, o cuando se alinee al
              comienzo del entrenamiento, siempre mueva lo más rápido posible.
              No pasee.
            </p>
          </div>
        </div>
        <div className="card-left">
          <div className="card-image">
            <img alt="" src={kumite} />
          </div>
          <div className="card-text">
            <p>
              Nunca practique kumite a menos que haya un instructor presente. Al
              practicar kumite con cinturón negro, haga lo mejor que pueda, pero
              muestre respeto por grado.{" "}
            </p>
          </div>
        </div>
        <div className="card-left">
          <div className="card-image">
            <img alt="" src={kumite1} />
          </div>
          <div className="card-text">
            <p>
              No pida un contrincante con un grado alto para kumite. Sin
              embargo, no debe negarse si un grado superior lo solicita.
            </p>
          </div>
        </div>
        <div className="card-right">
          <div className="card-image">
            <img alt="" src={dan} />
          </div>
          <div className="card-text">
            <p>
              {" "}
              Diríjase a su instructor como Sempai, Sensei o Shihan, según sea
              el caso. No se dirija a un instructor por su primer nombre en
              clase.
            </p>
          </div>
        </div>
        <div className="card-left">
          <div className="card-image">
            <img alt="" src={dogi1} />
          </div>
          <div className="card-text">
            <p>
              Su dogi debe lavarse y estar limpio todo el tiempo. Su nunca debe
              lavarse, ya que el espíritu de su entrenamiento duro.
            </p>
          </div>
        </div>
        <div className="card-bottom">
          <div className="card-image">
            <img alt="" src={osu} />
          </div>
          <div className="card-text">
            <p>
              Escuche atentamente las instrucciones del instructor. Confirme
              todas las instrucciones con un fuerte Osu.
            </p>
          </div>
        </div>
        <div className="card-bottom">
          <div className="card-image">
            <img alt="" src={prohibido1} />
          </div>
          <div className="card-text">
            <p>
              {" "}
              En aras de la seguridad y la limpieza, no use joyas durante el
              entrenamiento o cuando use su dogi.
            </p>
          </div>
        </div>
        <div className="card-right">
          <div className="card-image">
            <img alt="" src={respeto} />
          </div>
          <div className="card-text">
            <p>
              El instructor debe ser tratado con el respeto que usted esperaría
              como cortesía común.
            </p>
          </div>
        </div>
        <div className="card-bottom">
          <div className="card-image">
            <img alt="" src={limpio} />
          </div>
          <div className="card-text">
            <p>
              {" "}
              Mantenga uñas de los pies y de las manos limpias y cortas en todo
              momento. Siempre asegúrese de lavarse los pies, las uñas y las
              manos para entrenar.
            </p>
          </div>
        </div>
        <div className="card-left">
          <div className="card-image">
            <img alt="" src={limpiar} />
          </div>
          <div className="card-text">
            <p>
              {" "}
              Recuerde mantener su lugar de entrenamiento limpio y ordenado.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryComponent;
