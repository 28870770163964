import React from "react";
import BlogCardv3 from "components/common/Noticias/BlogCardv3";
import SmallSetPagination from "./../../pagination/SmallSetPagination";
export default function Other({
  posts,
  categories,
  get_blog_list_page,
  count,
  title,
}) {
  const handleNextPage = (event, nextPage) => {
    event.preventDefault();
    // Lógica para cambiar la página
    get_blog_list_page(nextPage);
    // Desplazar hacia arriba después de cambiar la página
  };
  const handlePreviousPage = (event, previousPage) => {
    event.preventDefault();
    // Lógica para cambiar la página
    get_blog_list_page(previousPage);
    // Desplazar hacia arriba después de cambiar la página
  };

  return (
    <div className="col-md-8">
      <h3 className="pb-4 mb-4 fst-italic border-bottom">
        {posts && posts.length > 0
          ? "Noticias Recientes"
          : "No hay publicaciones para mostrar"}
      </h3>

      {posts && posts.length === 0 ? (
        <div className="alert alert-warning text-center bg-red" role="alert">
          No hay publicaciones para mostrar
        </div>
      ) : (
        <>
          <BlogCardv3
            posts={posts && posts}
            categories={categories && categories}
          />
          <SmallSetPagination
            list_page={get_blog_list_page && get_blog_list_page}
            count={count && count}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
          />
        </>
      )}
    </div>
  );
}
