import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function CategoriesHeader({ categories }) {
  const location = useLocation();

  return (
    <>
      <div className="filtr-controls text-center lead text-uppercase mb-4">
        <Link
          to="/Noticias"
          className={`${
            location.pathname === "/Noticias"
              ? "active-category"
              : "hover-category"
          }`}
          data-filter="all"
        >
          all
        </Link>
        {categories && categories.length > 0 ? (
          categories.map((cat) => (
            <Link
              key={cat.id}
              to={`/Noticias/categoria/${cat.slug}`}
              className={`${
                location.pathname === `/Noticias/categoria/${cat.slug}`
                  ? "active-category selected-category"
                  : "hover-category"
              }`}
              data-filter={cat.id}
            >
              {cat.name}
            </Link>
          ))
        ) : (
          <span className="d-inline-block mx-3 position-relative" data-filter="1">
            No hay Categorias
          </span>
        )}
      </div>
    </>
  );
}
