import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Swal from "sweetalert2";
import logo from "components/assets/img/logo.jpg";
import HeaderSvg from "components/common/header/HeaderSvg";

const showInfoAlert = () => {
  Swal.fire({
    title: "Más información",
    text: "En cada zona, desliza hacia la derecha o izquierda en la tarjeta para ver más instructores",
    icon: "info",
    confirmButtonText: "¡Osu!",
    closeButtonAriaLabel: "Cerrar alerta",
  });
};

const zonaOrden = {
  Norte: 1,
  Centro: 2,
  Sur: 3,
};

export default function FadeModeSlider({ dojos }) {
  const [orderedZonas, setOrderedZonas] = useState([]);
  const [alertShown, setAlertShown] = useState(false);

  if (!alertShown) {
    showInfoAlert();
    setAlertShown(true);
  }

  useEffect(() => {
    const getPublishedZonas = (zonas) => {
      return zonas.filter((zona) =>
        dojos.some(
          (instructor) =>
            instructor.zona === zona && instructor.status === "published"
        )
      );
    };

    if (dojos && dojos.length > 0) {
      const zonas = [...new Set(dojos.map((instructor) => instructor.zona))];
      const publishedZonas = getPublishedZonas(zonas);
      const sortedZonas = publishedZonas.sort(
        (a, b) => zonaOrden[a] - zonaOrden[b]
      );

      setOrderedZonas(sortedZonas);
    }
  }, [dojos]);

  const getPublishedInstructorsByZona = (zona) =>
    dojos.filter(
      (instructor) =>
        instructor.zona === zona && instructor.status === "published"
    );

  const renderInstructorCard = (instructor) => (
    <div key={instructor.id} className="fade-mode-slide card-with-border">
      <img
        className="img-fluid d-block rounded-3 splashy-img mx-auto"
        style={{ maxHeight: "300px", objectFit: "cover" }}
        alt={`Instructor/a ${instructor.full_name}`}
        src={instructor.profile_image || logo}
        rel="preload"
      />
      <div className="card-body p-4 text-center">
        <p className="card-text">
          Soy{" "}
          <strong>
            {instructor.cargo} {instructor.full_name}
          </strong>
          , <br /> con el grado de {instructor.grado}, <br />
          actualmente me encuentro en <strong>
            {instructor.location}
          </strong>{" "}
          <br />
          en la zona <strong>{instructor.zona}</strong> de nuestro país <br />
          y soy el instructor del Dojo <br />{" "}
          <strong>{instructor.dojo_name}</strong>
        </p>
      </div>
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <HeaderSvg title={"Instructores"} desc={"Conoce a nuestros instructores"} />
      <div className="container">
        <div className="row">
          {orderedZonas.map((zona) => (
            <div key={zona} className="col-lg-4">
              <h5 className="rounded-full text-center mt-2">
                {zona === "Norte"
                  ? "Zona Norte"
                  : zona === "Centro"
                  ? "Zona Centro"
                  : "Zona Sur"}
              </h5>
              <p className="text-center">
                TOTAL DE DOJOS: {getPublishedInstructorsByZona(zona).length}
              </p>
              <hr className="mt-1 stick" />
              {dojos ? (
                <Slider {...settings}>
                  {getPublishedInstructorsByZona(zona).map((instructor) =>
                    renderInstructorCard(instructor)
                  )}
                </Slider>
              ) : (
                <p>No hay dojos disponibles en esta zona</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
