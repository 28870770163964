import React, { useState } from "react";
import moment from "moment";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/solid";
import { Modal } from "react-bootstrap"; // Importa los componentes de modal de Bootstrap
import { Link } from "react-router-dom";
moment.locale("es");

export default function Cards({ posts }) {
  const [expandedImage, setExpandedImage] = useState(null);

  const handleImageClick = (index) => {
    if (index === expandedImage) {
      setExpandedImage(null);
    } else {
      setExpandedImage(index);
    }
  };

  const handleClose = () => {
    setExpandedImage(null);
  };

  return (
    <>
      {posts && posts.length > 0 ? (
        <>
          <div className="container mt-5 ">
            <div className="row justify-content-center align-items-center">
              {posts.map((post, index) => (
                <div className="col-md-4 mb-4" key={index}>
                  <div className="card rounded bg-white border">
                    <div style={{ position: "relative" }}>
                      <img
                        src={post.thumbnail && post.thumbnail}
                        className="card-img-top img-fluid"
                        alt=""
                        style={{
                          height: "100px",
                          objectFit: "cover",
                          width: "100%",
                        }}
                        preload="auto"
                        onClick={() => handleImageClick(index)}
                      />
                      {expandedImage === index && (
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            zIndex: "10",
                          }}
                        >
                          <ArrowsPointingOutIcon
                            width="24"
                            height="24"
                            className="text-white"
                            onClick={handleClose}
                          />
                        </div>
                      )}
                    </div>
                    <div className="card-body">
                      <h5 className="card-title text-center">{post.title}</h5>
                      <hr />
                      <p className="card-text text-secondary">
                        {post.description.length > 90
                          ? post.description.slice(0, 100) + "..."
                          : post.description}
                      </p>
                      <Link
                        onClick={() => handleImageClick(index)}
                        className="btn btn-secondary btn-sm btn-block active text-white align-self-end item-center"
                      >
                        Leer más
                      </Link>
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">{post.category.name}</li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {expandedImage !== null && (
            <Modal
              show={expandedImage !== null}
              onHide={handleClose}
              centered
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{posts[expandedImage].title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={
                    posts[expandedImage].thumbnail &&
                    posts[expandedImage].thumbnail
                  }
                  className="img-fluid"
                  alt={posts[expandedImage].title && posts[expandedImage].title}
                />
              </Modal.Body>
              <Modal.Footer>
                <p className="mt-3 text-center">
                  {posts[expandedImage].description}
                </p>
              </Modal.Footer>
            </Modal>
          )}
        </>
      ) : (
        <div className="alert alert-warning text-center bg-red" role="alert">
          No hay actividades para mostrar
        </div>
      )}
    </>
  );
}
