import React from "react";
import Layout from "components/Layout/Default";
import LeadershipSection from "components/pages/Nosotros/FundPres/LeadershipSection";
export default function index() {
  return (
    <Layout
      title={"Nuestros Lideres | Iko Matsushima Chile"}
      desc={"Conoce a los lideres de Iko Matsushima Chile"}
      url={"https://www.ikomatsushima.cl/Fundador&Presidente"}
    >
      <LeadershipSection />
    </Layout>
  );
}
