import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get_dojos } from "../../../redux/actions/dojos/dojos";
import Cards from "./Team/Cards";
import HeaderSvg from "components/common/header/HeaderSvg";
import { Spinner } from "react-bootstrap";

function Instructores({ get_dojos, dojos }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0); // Scroll to the top of the page
        await get_dojos();
        setLoading(false);
      } catch (error) {
        // Manejar el error estableciendo el estado de error
        setError("Error al cargar los datos");
        setLoading(false);
      }
    };
    fetchData();
  }, [get_dojos]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Obteniendo Directores Oficiales</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    );
  }

  return (
    <>
      <HeaderSvg />

      <section className="TEAMDIR">
        <div className="container">
          <div className="row justify-content-center mb-4">
            <div className="col-md-7 text-center">
              <h3 className="mb-3">Nuestros directores</h3>
              <h6 className="subtitle font-weight-normal custom-subtitle">
                Conoce a los directores de Iko Matsushima Chile
              </h6>
            </div>
          </div>
        </div>
        <div className="container team3">
          <div className="row bg-light row-cols-1 row-cols-md-2 row-cols-lg-3 container text-center ">
            <Cards dojos={dojos && dojos} />
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({
  dojos: state.dojos.dojo_list,
});

export default connect(mapStateToProps, {
  get_dojos,
})(Instructores);
