import React, { useEffect } from "react";
import sosaiImg from "assets/home/sosai.webp";
import kanchoImg from "assets/home/kancho.webp";

const LeadershipCard = ({ title, name, bio }) => (
  <div className="leadership-card">
    <img
      src={name === "Kancho Yoshikazu Matsushima" ? kanchoImg : sosaiImg}
      alt={name}
      className="leadership-image"
    />
    <h3>{title}</h3>
    <h4>{name}</h4>
    {bio.map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ))}
    <a
      href={name === "Kancho Yoshikazu Matsushima" ? "http://kyokushin-matsushima.jp/e/" : "https://www.masutatsuoyama.com/"}
      target="_blank"
      rel="noopener noreferrer"
      className="saber-mas-btn"
    >
      Saber más
    </a>
  </div>
);

const LeadershipSection = () => {
  // Preload images
  useEffect(() => {
    const sosaiImage = new Image();
    sosaiImage.src = sosaiImg;

    const kanchoImage = new Image();
    kanchoImage.src = kanchoImg;
  }, []);

  const leaderData = [
    {
      title: "Fundador",
      name: "Masutatsu Oyama",
      bio: [
        "Sosai Masutatsu Oyama, más conocido como Mas Oyama, fue un karateka surcoreano-japonés y fundador del Kyokushin Karate, considerado uno de los estilos de karate más influyentes y respetados del siglo XX.",
        "Nacido el 27 de julio de 1923 en Corea, Oyama se trasladó a Japón a una edad temprana, donde comenzó su entrenamiento en artes marciales. Mas Oyama es famoso por su extraordinaria fuerza y su riguroso régimen de entrenamiento, incluyendo la ruptura de tablones y ladrillos con sus manos desnudas, así como retiros solitarios en las montañas para practicar y meditar.",
        "Fue pionero en el concepto de combate de contacto completo en el karate. En 1953, fundó su propio dojo en Tokio, que eventualmente se convirtió en la Organización Mundial de Karate Kyokushin, extendiéndose globalmente. Su legado continúa a través de la práctica mundial del Kyokushin Karate.",
      ],
    },
    {
      title: "Presidente",
      name: "Kancho Yoshikazu Matsushima",
      bio: [
        "Kancho Yoshikazu Matsushima es un renombrado maestro de karate y líder en el mundo de las artes marciales, específicamente dentro del estilo Kyokushin Karate. Su dedicación y contribuciones a la difusión del Kyokushin han sido fundamentales para su popularidad global.",
        "Luego del fallecimiento de Mas Oyama, Matsushima ha liderado una de las principales organizaciones de Kyokushin, enfocándose en preservar los enseñanzas originales y promover la disciplina entre practicantes de todas las edades.",
        "Bajo su liderazgo, se han organizado numerosos torneos y seminarios internacionales, fortaleciendo la comunidad de Kyokushin y manteniendo alto el espíritu de lucha y la excelencia técnica.",
        "Respetado por su profundo conocimiento y habilidad marcial, Matsushima inspira a karatekas en todo el mundo, asegurando que el legado de Kyokushin perdure y continúe evolucionando, fiel a sus raíces tradicionales.",
      ],
    },
    // Puedes agregar más datos de líderes aquí si es necesario
  ];

  return (
    <section className="leadership-section">
      {leaderData.map((leader, index) => (
        <LeadershipCard key={index} {...leader} />
      ))}
    </section>
  );
};

export default LeadershipSection;
