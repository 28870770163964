import React from "react";
import Layout from "components/Layout/Default";
import DojoKun from "./DojoKun";
export default function index() {
  return (
    <Layout title={"Juramento / Dojo Kun | Iko Matsushima Chile"} url={"https://www.ikomatsushima.cl/DojoKun"}
      desc={"Conoce el código de ética de Kyokushin, el cual se recita al final de cada entrenamiento y es conocido como Dojo Kun."}
    >
      <DojoKun />
    </Layout>
  );
}
