import React from "react";
import BlogCards from "../cards/BlogCards";
import SmallSetPagination from "components/pagination/SmallSetPagination";
import CategoriesHeader from "components/Layout/header/CategoriesHeader";

export default function BlogList({
  posts,
  get_blog_list_page,
  count,
  categories,
  button,
}) {
  
  return (
    <div className="blog-home2 py-5">
      <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h3 className="my-3">¿Que esta pasando en nuestra organización?</h3>
              <h6 className="subtitle font-weight-normal">
              Descubre las ultimas <a className="subtitle font-weight-medium " href="https://www.ikomatsushima.cl/Noticias" style={{ listStyle:"none"}} aria-label="Enterate de las Noticias sobre nuestra Organizacion">Noticias</a> de nuestra organización
            </h6>
            <span className="divider"></span>
            </div>
          {categories ? (
            <>
              <CategoriesHeader categories={categories && categories} />
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="row mt-4">
          {/* Aquí va el código que se repite */}
          {posts && posts.length > 0 ? (
            posts.map((post, index) => <BlogCards key={index} data={post} />)
          ) : (
            <span
              className="d-inline-block mx-3 position-relative"
              data-filter="1"
            >
              No hay publicaciones
            </span>
          )}
          {get_blog_list_page ? (
            <>
              {" "}
              <SmallSetPagination
                list_page={get_blog_list_page}
                list={posts}
                count={count}
              />
            </>
          ) : (
            <></>
          )}
          {button === "false" ? (
            <a href="/Noticias" className="text-center" style={{}}>Ver mas Noticas...</a>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
