import React from 'react'
import Galeria from './Galeria'
export default function index() {
  return (
    <div>
      
      <Galeria/>
    </div>
  )
}
