import React from "react";
import moment from "moment";
import "moment/locale/es";
import { Link } from "react-router-dom";
import logo from "components/assets/img/logo.jpg";
import Swal from "sweetalert2";
import Modal from "react-modal";

const alert = () => {
  Swal.fire({
    title: "¡Lo sentimos!",
    text: "No se a proporcionado un link para ver más fotos de esta actividad.",
    icon: "warning",
    confirmButtonText: "Aceptar",
  });
};
Modal.setAppElement("#root");

const Cards = ({ posts }) => {
  return (
    <>
      {posts &&
        posts.map((post) => {
          return (
            <div className="photo-carousel" key={post.id}>
              <div className="gallery-card">
                <img
                  src={post.thumbnail ? post.thumbnail : logo}
                  alt={post.title}
                  className="gallery-image"
                  rel="preload"
                  width={250}
                  height={250}
                />
                <div className="gallery-info">
                  <h3 className="gallery-title">{post.title} </h3>
                  <p className="gallery-description">
                   evento realizado el {moment(post.date).format("LL")}
                  </p>
                  <p className="gallery-description">{post.description}</p>
                  <p className="gallery-description">
                    Categoria:{" "}
                    <Link to={`/Galeria/categoria/${post.category.slug}`}> {post.category.name} </Link>
                  </p>
                  {post.link_drive ? (
                    <Link to={post.link_drive} className="button-50" target="_blank" rel="noopener noreferrer">
                      Ver más fotos
                    </Link>
                  ) : (
                    <button onClick={alert} className="button-50 viewlink">
                      ver más fotos
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default Cards;