/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Noticias from "components/Layout/Noticias";
import NoticiasCat from "../Noticias/NoticiasCat";
import NoticiasHeader from "components/Layout/header/NoticiasHeader";
import Other from "../Noticias/Other";
import Aside from "../Noticias/Aside";

export default function BlogList({
  posts,
  get_blog_list_page,
  count,
  categories,
  button,
}) {
  return (
    <Noticias
    //url={"https://www.ikomatsushima.cl/Noticias"} title={"¿Que esta Pasando? | Iko Matsushima Chile"} 
    >
      <NoticiasCat categories={categories && categories} />
      <main className="container">
        <NoticiasHeader
          title={"¿Que esta Pasando en la Organizacion Iko Matsushima?"}
          subtitle={
            "Descubre todas las noticias relevantes de nuestras participaciones como organizacion en todas las actividades en las que estamos presentes"
          }
          button={true}
        />

        

        <div className="row g-5">
          <Other
            posts={posts && posts}
            categories={categories && categories}
            get_blog_list_page={get_blog_list_page && get_blog_list_page}
            count={count && count}
            title={"Todas las Publicaciones"}
          />

          <Aside
            categories={categories && categories}
            title={"Acerca de Nosotros"}
            description={
              "Somos una organizacion sin fines de lucro que busca el desarrollo de la comunidad"
            }
          />
        </div>
      </main>
    </Noticias>
  );
}
