import React, { useState, useEffect } from "react";
import Layout from "components/Layout/Default";
import { connect } from "react-redux";
import { get_dojos } from "./../../../../../redux/actions/dojos/dojos";
import Cards from "./Cards";
import HeaderSvg from "components/common/header/HeaderSvg";

function Instructores({ get_dojos, dojos }) {
  const [setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0); // Scroll to the top of the page
        await get_dojos();
      } catch (error) {
        // Manejar el error estableciendo el estado de error
        console.error(error);
      }
    };
    fetchData();
  }, [get_dojos, setError]);

  return (
    <Layout
      title={"Dojos Operadores y BranchChief | Iko Matsushima Chile"}
      desc={
        "Cononce a nuestros Branchiefs y Dojos Operadores Oficiales de Iko Matsushima en Chile"
      }
      url={"https:ikomatsushima.cl/Dojo-operadores"}
    >
      <section className="">
        <div className="">
          <HeaderSvg
            title={
              "Conoce a nuestros BranchChiefs y Dojos Operadores Oficiales"
            }
            desc={"de Iko Matsushima en Chile"}
          />

          <div className="container">
            <Cards dojos={dojos && dojos} />
          </div>
        </div>
      </section>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  dojos: state.dojos.dojo_list,
});

export default connect(mapStateToProps, {
  get_dojos,
})(Instructores);
