import React from 'react'

const HeaderSvg = ({title, desc}) => {
  return (
    <div className="background-svg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#273036"
                fillOpacity="0.2"
                d="M0,320L34.3,293.3C68.6,267,137,213,206,213.3C274.3,213,343,267,411,272C480,277,549,235,617,218.7C685.7,203,754,213,823,218.7C891.4,224,960,224,1029,229.3C1097.1,235,1166,245,1234,250.7C1302.9,256,1371,256,1406,256L1440,256L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
              ></path>
            </svg>
            <div className="text-dark  border rounded border-0  d-flex flex-column justify-content-between flex-lg-row p-4 p-md-6">
              <div className="">
                <h1 className="fw-bold text-center mb-3">
                  {title}
                </h1>
                <h3 className="text-center">
                 {desc}
                </h3>
              </div>
            </div>
          </div>
  )
}

export default HeaderSvg