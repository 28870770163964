import React from "react";
import Layout from "components/Layout/Default";
import Hero from "./Hero";
import Team from "./Team";
import Latest from "./Latest";
export default function index() {
  return (
    <>
      <Layout
        title={"Home | Iko Matsushima Chile"}
        desc={"Sitio Oficial de Iko Matsushima CHile"}
        url={"https://www.ikomatsushima.cl/"}
      >
        <Hero />
        <Team />
        <Latest />
      </Layout>
    </>
  );
}
