import React, { useState, useEffect } from "react";
import fondo from "../../../../src/assets/home/Pictures-Home/fondo.webp";
import fondo1 from "../../../../src/assets/home/Pictures-Home/fondo1.webp";
import fondo2 from "../../../../src/assets/home/Pictures-Home/fondo2.webp";

const backgrounds = [fondo, fondo1, fondo2];

// Preload images
backgrounds.forEach((imageUrl) => {
  const img = new Image();
  img.src = imageUrl;
});

export default function Hero() {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % backgrounds.length);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="hero-section">
      <div
        className="image-home active"
        style={{
          backgroundImage: `url(${backgrounds[currentImage]})`,
          height: "600px",
          transition: "background-image 1s ease-in-out",
        }}
      >
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-md-6 text-center text-md-start d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center text-white">
              <div style={{ maxWidth: "350px" }}>
                {currentImage === 0 && (
                  <>
                    <h1 className="text-uppercase fw-bold">
                      Bienvenidos a iko Matsushima Chile
                    </h1>
                    <p className="my-3 text-white">
                      "Seguiré a mi dios y eternas verdades y jamás olvidaré la
                      verdadera virtud de la humildad."
                    </p>
                    <a
                      className="btn btn-primary btn-lg me-2"
                      role="button"
                      href="/Dojos-en-Chile"
                    >
                      Conócenos
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="image-indicator">
        {backgrounds.map((_, index) => (
          <div
            key={index}
            className={`indicator responsive ${
              index === currentImage ? "active" : ""
            }`}
          ></div>
        ))}
      </div>
    </section>
  );
}
