/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { get_blog } from "../../../redux/actions/blog/blog";
import { get_categories } from "../../../redux/actions/categories/categories";
import Noticias from "components/Layout/Noticias";
import NoticiasCat from "components/common/Noticias/NoticiasCat";
import DOMPurify from "dompurify";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

function Details({ get_blog, post, categories, get_categories }) {
  const params = useParams();
  const slug = params.slug;
  const postUrl = window.location.href;
  const [loading, setLoading] = useState(true); // Estado de carga de la noticia

  useEffect(() => {
    // Cargar categorías y post al montar el componente
    get_categories();
    get_blog(slug)
      .then(() => setLoading(false)) // Una vez que se carga la noticia, cambiar el estado de carga
      .catch((error) => {
        console.error("Error al obtener la noticia:", error);
        setLoading(false); // En caso de error, cambiar el estado de carga
      });
  }, [get_blog, slug, get_categories]);

  return (
    <Noticias post={post && post} categories={categories && categories}>
      <NoticiasCat categories={categories && categories} />

      {loading ? ( // Mostrar skeleton de carga mientras se carga la noticia
        <div className="container">
          {/* Estructura del skeleton de carga */}
          <div className="jumbotron jumbotron-fluid mb-3 pl-0 pt-0 pb-0 bg-white position-relative">
            <div className="h-100 tofront">
              <div className="row justify-content-between">
                <div className="col-md-6 pt-6 pb-6 pr-6 align-self-center">
                  <div className="skeleton-loading w-75 mb-2"></div> {/* Placeholder para título */}
                  <div className="skeleton-loading w-100 mb-3"></div> {/* Placeholder para descripción */}
                  <div className="skeleton-loading w-50 mb-3"></div> {/* Placeholder para imagen */}
                </div>
                <div className="col-md-6 pr-0">
                  <div className="skeleton-loading w-100 mb-3" style={{ height: "300px" }}></div> {/* Placeholder para imagen */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        post && post.slug === slug && ( // Mostrar contenido de la noticia una vez que se carga completamente
          <>
            <div className="container">
              <div className="jumbotron jumbotron-fluid mb-3 pl-0 pt-0 pb-0 bg-white position-relative">
                <div className="h-100 tofront">
                  <div className="row justify-content-between">
                    <div className="col-md-6 pt-6 pb-6 pr-6 align-self-center">
                      <p className="text-uppercase font-weight-bold">
                        <Link
                          className="text-danger"
                          to={`/Noticias/categoria/${post.category.slug}`}
                        >
                          {post.category.name}
                        </Link>
                      </p>
                      <h1 className="display-4 secondfont mb-3 font-weight-bold">
                        {post.title}
                      </h1>
                      <p className="mb-3">{post.description}</p>
                    </div>
                    <div className="col-md-6 pr-0">
                      <img
                        src={post.thumbnail}
                        alt={post.title}
                        className="img-fluid"
                        width={300}
                        height={300}
                        preload="auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container pt-4 pb-4">
              <div className="row justify-content-center">
                <div className="col-lg-2 pr-4 mb-4 col-md-10">
                  <div className="sticky-top text-center">
                    <div className="text-muted">Comparte esta noticia!! </div>
                    <div className="share d-inline-block">
                      <FacebookShareButton
                        title="Compartir en Facebook"
                        url={postUrl}
                        style={{
                          fontSize: "25px",
                          color: "red",
                          HoverColor: "blue",
                        }}
                      >
                        <i className="fab fa-facebook-f" />
                      </FacebookShareButton>{" "}
                      <TwitterShareButton
                        url={postUrl}
                        title={post.title}
                        style={{ fontSize: "25px", color: "red" }}
                      >
                        <i className="fab fa-twitter" />
                      </TwitterShareButton>{" "}
                      <WhatsappShareButton
                        url={postUrl}
                        title={post.title}
                        style={{ fontSize: "25px", color: "red" }}
                      >
                        <i className="fab fa-whatsapp" />
                      </WhatsappShareButton>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-8">
                  <article className="article-post">
                    {post.content && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            post.content.replace(
                              /<img/g,
                              '<img class="img-fluid"'
                            )
                          ),
                        }}
                        className="post-content"
                      />
                    )}
                    {post.file && (
                      <a
                        href={post.file}
                        className="btn btn-secondary btn-lg mx-auto d-block mt-4"
                      >
                        Descarga el informe completo
                      </a>
                    )}
                  </article>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </Noticias>
  );
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  post: state.blog.post,
});

export default connect(mapStateToProps, {
  get_blog,
  get_categories,
})(Details);
