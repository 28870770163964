import React, { useEffect} from "react";
import { connect } from "react-redux";
import NoticiasCat from "components/common/Noticias/NoticiasCat";
import { get_categories } from "./../../../redux/actions/categories/categories";
import {
  get_blog_list_category_page,
  get_blog_list_category,
} from "./../../../redux/actions/blog/blog";
// import CategoriesHeader from "components/Layout/header/CategoriesHeader";
import { useParams } from "react-router-dom";
import Noticias from "components/Layout/Noticias";
import NoticiasHeader from "components/Layout/header/NoticiasHeader";
// import PostViews from "components/common/Noticias/PostViews";
import Other from "components/common/Noticias/Other";
import Aside from "components/common/Noticias/Aside";

function Categorias({
  get_categories,
  categories,
  get_blog_list_category,
  get_blog_list_category_page,
  get_popular_blogs,
  posts,
  count,
  next,
  previous,
}) {
  const params = useParams();
  const slug = params.slug;

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await get_categories();
        await get_blog_list_category(slug);
        await get_popular_blogs(3);
      } catch (error) {
        // Manejar el error estableciendo el estado de error
      }
    };

    fetchData();
  }, [get_categories, get_blog_list_category, slug, get_popular_blogs]);
  const handlePageChange = (page) => {
    // Llamar a la acción para obtener la siguiente página de blogs de la categoría
    get_blog_list_category_page(slug, page);
  };
  return (
    <Noticias categories={categories&&categories}>
      
      <NoticiasCat categories={categories && categories} />
      <main className="container">
        <NoticiasHeader
          title={"¿Que esta Pasando en nuestra Organizacion"}
          subtitle={
            "Descubre todas las noticias relevantes de nuestras participaciones como organizacion en todas las actividades en las que formamos parte"
          }
          button={true}
        />

        {/* <PostViews getPopularBlogs={get_popular_blogs} /> */}

        <div className="row g-5">
          <Other
            posts={posts && posts}
            categories={categories && categories}
            get_blog_list_page={handlePageChange && handlePageChange}
            count={count && count}
            
          />

          <Aside
            categories={categories && categories}
            title={"Acerca de Nosotros"}
            description={
              "Somos una organizacion sin fines de lucro que busca el desarrollo de la comunidad"
            }
          />
        </div>
      </main>
    </Noticias>
  );
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  posts: state.blog.blog_list_category,
  count: state.blog.count,
  next: state.blog.next,
  previous: state.blog.previous,
});

export default connect(mapStateToProps, {
  get_categories,
  get_blog_list_category,
  get_blog_list_category_page,
})(Categorias);
