import React, { useEffect } from "react";
import Default from "components/Layout/Default";
import { connect } from "react-redux";
// Importa aquí tu archivo CSS para asegurarte de que los estilos se apliquen correctamente.
import HeaderSvg from "components/common/header/HeaderSvg";
import {
  get_gallery_list,
  get_gallery_list_page,
  get_gallery_list_category_page,
} from "../../../redux/actions/gallery/gallery";
import { get_categories } from "./../../../redux/actions/categories/categories";
import Categories from "./Categories";
import GaleriaList from "./GaleriaList";
function Galeria({
  get_categories,
  get_gallery_list_page,
  get_gallery_list,
  gallery,
  categories,
  posts,
  count,
  next,
  previous,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        await get_categories();
        await get_gallery_list();
      } catch (error) {
        // Manejar el error estableciendo el estado de error
        console.error("no se pudo cargar la galeria de fotos");
      }
    };
    fetchData();
  }, [get_categories, get_gallery_list]);

  return (
    <Default>
      <HeaderSvg
        title={"Revive los momentos de nuestras Actividades"}
        desc={"desde seminarios, Campeonatos, Examenes de grados y Más"}
      />

      <Categories categories={categories && categories} />

      <GaleriaList
        categories={categories && categories}
        get_gallery_list_page={
          get_gallery_list_page && get_gallery_list_page
        }
        post={posts && posts}
        count={count && count}
      />
    </Default>
  );
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  posts: state.gallery.gallery_list,
  count: state.gallery.count,
  next: state.gallery.next,
  previous: state.gallery.previous,
});
export default connect(mapStateToProps, {
  get_categories,
  get_gallery_list,
  get_gallery_list_page,
  get_gallery_list_category_page,
})(Galeria);
