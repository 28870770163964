import React, { Fragment } from "react";
import Navbar from "./Noticias/Navbar";
import Footer from "./Noticias/Footer.js";
import { Helmet } from "react-helmet";
export default function Noticias({ children, desc, categories, post }) {
  return (
    <Fragment>
      <Helmet>
        <title>¿Que esta Pasando en Iko Matsushima Chile?</title>
        <meta name="description" content={desc} />
      </Helmet>
      <div className="BLOG">
        <Navbar />
        {children}
        <Footer />
      </div>
    </Fragment>
  );
}
