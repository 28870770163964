import React, { useEffect} from "react";
import { connect } from "react-redux";
// import Layout from "components/Layout/index";
import { get_categories } from "./../../../redux/actions/categories/categories";
import {
  get_blog_list,
  get_blog_list_page,
} from "./../../../redux/actions/blog/blog";
import BlogList2 from "components/common/List/BlogList2";

function Blog({
  get_categories,
  categories,
  get_blog_list,
  get_blog_list_page,
  posts,
  count,
  next,
  previous,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        await get_categories();
        await get_blog_list();
      } catch (error) {
        // Manejar el error estableciendo el estado de error
        console.error(error);
        
      }
    };
    fetchData();
  }, [get_categories, get_blog_list]);
  return (
    <>
    
      <BlogList2
        categories={categories && categories}
        posts={posts && posts}
        get_blog_list_page={get_blog_list_page && get_blog_list_page}
        count={count && count}
      />
      
    </>
  );
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  posts: state.blog.blog_list,
  count: state.blog.count,
  next: state.blog.next,
  previous: state.blog.previous,
});

export default connect(mapStateToProps, {
  get_categories,
  get_blog_list,
  get_blog_list_page,
})(Blog);