import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_categories } from "./../../../redux/actions/categories/categories";
import {
  get_blog_list,
  get_blog_list_page,
} from "./../../../redux/actions/blog/blog";
import BlogList from "components/common/List/BlogList";

function Latest({
  get_categories,
  categories,
  get_blog_list,
  get_blog_list_page,
  posts,
  count,
  next,
  previous,
}) {
  const [setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0); // Scroll to top of the page
        await get_categories();
        await get_blog_list(3);
      } catch (error) {
        // Manejar el error estableciendo el estado de error
        console.error(error);
      }
    };
    fetchData();
  }, [setError, get_categories, get_blog_list, get_blog_list_page]);
  return (
    <>
      <BlogList posts={posts && posts} button={"true"} />
    </>
  );
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  posts: state.blog.blog_list,
  count: state.blog.count,
  next: state.blog.next,
  previous: state.blog.previous,
});
export default connect(mapStateToProps, {
  get_categories,
  get_blog_list,
  get_blog_list_page,
})(Latest);
