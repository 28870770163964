import { useEffect } from "react";
import { connect } from "react-redux";
import Default from "components/Layout/Default";
import Categories from "./Categories";

import { get_categories } from "./../../../redux/actions/categories/categories";
import { useParams } from "react-router-dom";
import {
  get_gallery_list_category,
  get_gallery_list_category_page,
} from "../../../redux/actions/gallery/gallery";
import GaleriaList from "components/pages/gallery/GaleriaList";
import HeaderSvg from "components/common/header/HeaderSvg";
function Actividades({
  get_categories,
  categories,
  get_gallery_list_category,
  get_gallery_list_category_page,
  posts,
  count,
  next,
  previous,
}) {
  const params = useParams();
  const slug = params.slug;
  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);
        await get_categories();
        // Verificar si el slug está definido antes de hacer la solicitud
        if (slug) {
          await get_gallery_list_category(slug);
        } else {
          await get_gallery_list_category();
        }
      } catch (error) {
        console.error("Error al obtener la galeria de imagenes:", error);
      }
    };
    fetchData();
  }, [get_categories, get_gallery_list_category, slug]);
  const handlePageChange = (page) => {
    // Llamar a la acción para obtener la siguiente página de blogs de la categoría
    get_gallery_list_category_page(slug, page);
  };

  return (
    <Default
      title={"Actividades | Iko Matsushima Chile"}
      desc={"Conoce las actividades que se realizan en Iko Matsushima Chile"}
      url={"https://www.ikomatsushima.cl/Galeria"}
    >
      <HeaderSvg title={"Revive los momentos de nuestras Actividades"} desc={"desde seminarios, Campeonatos, Examenes de grados y Más"} />

      <div className="container">
        <div>
          <Categories
            categories={categories && categories}
            posts={posts && posts}
          />
          <GaleriaList
            categories={categories && categories}
            get_actividades_list_page={handlePageChange && handlePageChange}
            post={posts && posts}
            count={count && count}
            next={next && next}
            previous={previous && previous}
          />
        </div>
      </div>
    </Default>
  );
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  posts: state.gallery.gallery_list_category,
  count: state.gallery.count,
  next: state.gallery.next,
  previous: state.gallery.previous,
});

export default connect(mapStateToProps, {
  get_categories,
  get_gallery_list_category,
  get_gallery_list_category_page,
})(Actividades);
